export default {
    "Login": "Connexion",
    "Logout": "Déconnexion",
    "Account": "Compte",
    "Home": "Accueil",
    "Invoices": "Factures",
    "Invoice requests": "Demandes de factures",
    "Billing address": "Adresse de facturation",
    "Billing addresses": "Adresses de facturation",
    "Add": "Ajouter",
    "Username": "Nom d'utilisateur",
    "Are you sure to delete this address?": "Êtes-vous sûr de supprimer cette adresse ?",
    "Address details": "Détails de l'adresse",
    "Address updated successfully.": "Adresse mise à jour avec succès.",
    "Create a new address": "Créer une nouvelle adresse",
    "Address created successfully.": "Adresse créée avec succès.",
    "Address list": "Liste des adresses",
    "Create": "Créer",
    "Civility / Type": "Civilité / Type",
    "Name": "Nom",
    "Address": "Adresse",
    "No addresses found": "Aucune adresse trouvée",
    "Invoice Request": "Demande de facturation",
    "2. Add your receipts": "2. Ajoutez vos reçus",
    "Next": "Suivant",
    "Request an invoice": "Demander une facture",
    "4. Confirm": "4. Confirmer",
    "Please double check your details to make sure they are correct.": "Veuillez vérifier vos informations pour vous assurer qu'elles soient correctes.",
    "The creation of an invoice is binding and cannot be undone.": "La création d'une facture est contraignante et ne peut être annulée.",
    "None": "Aucun",
    "Back to this step": "Revenir à cette étape",
    "Allocate the invoice to the current month.": "Affectez la facture au mois en cours.",
    "Allocate the invoice to the previous month.": "Affectez la facture au mois précédent.",
    "Send me an email as soon as the status of my request changes.": "Envoyez-moi un e-mail dès que l'état de ma demande change.",
    "Send the invoice request": "Envoyer la demande de facture",
    "5. Finished": "5. Terminé",
    "Invoice requested successfully.": "Facture demandée avec succès.",
    "Invoice request list": "Liste des demandes de factures",
    "3. Options": "3. Options",
    "Month allocation": "Affectation de la facture",
    "Current month": "Mois en cours",
    "Previous month": "Mois précédent",
    "The invoice can be allocated to the current or previous month. The date of the invoice is set depending on this choice.": "La facture peut être affectée au mois en cours ou au mois précédent. La date de la facture est fixée en fonction de ce choix.",
    "The date of the invoice will be today.": "La facture sera datée d'aujourd'hui.",
    "The date of the invoice will be the last day of the previous month. This feature is available until the 5th day of the current month.": "La facture sera datée du dernier jour du mois précédent. Cette fonctionnalité est disponible jusqu'au 5ème jour du mois en cours.",
    "Notify me": "Prévenez-moi",
    "E-mail": "Courriel",
    "Send me a notification as soon as the status of my request changes.": "Envoyez-moi une notification dès que l'état de ma demande change.",
    "1. Select an address": "1. Sélectionnez une adresse",
    "New address": "Nouvelle adresse",
    "Save address": "Enregistrer l'adresse",
    "The data entered will not be saved and is only valid for this request.": "Les données saisies ne seront pas enregistrées et ne sont valables que pour cette demande.",
    "You can save this address and thus reuse it for further invoices.": "Vous pouvez enregistrer cette adresse et ainsi la réutiliser pour d'autres factures.",
    "Invoice Request Details": "Détails de la demande de facture",
    "Date": "Date",
    "Status": "Statut",
    "Invoice Request List": "Liste des demandes de factures",
    "Number of receipts": "Nombre de reçus",
    "Failed": "Échoué",
    "No invoice requests found": "Aucune demande de facture trouvée",
    "Downloading invoice": "Téléchargement de la facture",
    "If your download does not start, please click here:": "Si votre téléchargement ne démarre pas, veuillez cliquer ici:",
    "Invoice List": "Liste des factures",
    "My Invoices": "Mes factures",
    "Invoices of my sites": "Factures de mes sites",
    "Page not found.": "Page non trouvée.",
    "TotalEnergies Invoicing Platform": "TotalEnergies Plateforme de Facturation",
    "Preparing": "En train de préparer",
    "Your request has been submitted successfully and will be processed shortly.": "Votre demande a été soumise avec succès et sera traitée sous peu.",
    "Processing": "En traitement",
    "Your request is currently being processed.": "Votre demande est en cours de traitement.",
    "Finished": "Fini",
    "Your request has been processed successfully.": "Votre demande a été traitée avec succès.",
    "Unfortunately, your request could not be processed successfully.": "Malheureusement, votre demande n'a pas pu être traitée avec succès.",
    "VAT number is required": "Le numéro de TVA est requis",
    "Type is required": "Le type est requis",
    "Name is required": "Le nom est requis",
    "Civility is required": "La civilité est requise",
    "First name is required": "Le prénom est requis",
    "Last name is required": "Le nom est requis",
    "Address is required": "L'adresse est requise",
    "Zip/Postal code is required": "Le code postal est requis",
    "City is required": "La ville est requise",
    "Country is required": "Le pays est requis",
    "E-mail is required": "Le courriel est requis",
    "E-mail is invalid": "Le courriel est invalide",
    "Company": "Compagnie",
    "Private": "Particulier",
    "Civility": "Civilité",
    "Type": "Type",
    "Please select": "Veuillez sélectionner",
    "customer_title_0": "Autre",
    "customer_title_1": "M.",
    "customer_title_2": "Mme",
    "customer_title_3": "Mlle",
    "customer_title_4": "SA",
    "customer_title_5": "SARL",
    "customer_title_6": "ASBL",
    "customer_title_7": "SPRL",
    "customer_title_8": "SCRL",
    "customer_title_9": "SE",
    "customer_title_10": ".",
    "customer_title_11": "M.Mme.",
    "customer_title_12": "Indép.",
    "customer_title_13": "EURL",
    "customer_title_14": "SAS",
    "customer_title_15": "SNC",
    "customer_title_16": "SCS",
    "customer_title_17": "SCA",
    "First name": "Prénom",
    "Last name": "Nom de famille",
    "Company type": "Type de compagnie",
    "Company name": "Nom de la compagnie",
    "VAT": "TVA",
    "VAT number": "Numéro de TVA",
    "Starts with an country code (usually 2 letters) followed by up to 13 characters, e.g. BE09999999XX, FRXX999999999, NL999999999B01": "Commence par un code de pays (généralement 2 lettres) suivi d'un maximum de 13 caractères, par ex. BE09999999XX, FRXX999999999, NL999999999B01",
    "Street address": "Rue",
    "Zip/Postal code": "Code postal",
    "City": "Ville",
    "Country": "Pays",
    "Free text": "Texte libre",
    "No invoices found": "Aucune facture trouvée",
    "ID": "ID",
    "File": "Fichier",
    "Receipts": "Reçus",
    "Documents": "Des documents",
    "Petrol station": "Station-service",
    "Receipt number": "Numéro de reçu",
    "Amount (Incl. Tax)": "Montant TVAC",
    "Options": "Options",
    "Assignments": "Affectations",
    "Administration": "Administration",
    "Search by number, name or address": "Recherche par numéro, nom ou adresse",
    "If the station does not appear in this list, please contact directly the manager of the station where the purchase was made.": "Si la station n'apparaît pas dans cette liste, veuillez-vous adresser directement au gérant de la station où la transaction a été effectuée.",
    "The invoice cannot be assigned to the previous month. Because it has a receipt for the current month.": "La facture ne peut pas être affectée au mois précédent. Parce qu'il a un reçu pour le mois en cours.",
    "If you still need an invoice for the previous month. Go back one step and delete the corresponding receipt.":"Si vous avez encore besoin d'une facture pour le mois précédent. Revenez en arrière et supprimez le reçu correspondant.",

    //Receipt Status
    "difference in the receipt total amount": "différence dans le montant total du reçu",
    "unknown paymode": "mode de paiement inconnu",
    "receipt already invoiced": "reçu déjà facturé",
    "receipt paid with a company card": "reçu payé avec une carte d'entreprise",
    "receipt not found": "reçu introuvable",
    "ok" : "ok",

    "France" : "France",
    "Belgium" : "Belgique",
    "Germany" : "Allemagne",
    "Luxembourg" : "Luxembourg",
    "The Netherlands" : "Pays Bas",
    "Spain" : "Espagne",
    "Portugal" : "Portugal",
    "Italy" : "Italie",
    "The United Kingdom" : "Grande Bretagne",
    "Switzerland" : "Suisse",
    "Denmark" : "Danemark",
    "Austria" : "Autriche",
    "Bulgaria" : "Bulgarie",
    "Finland" : "Finlande",
    "Hungary" : "Hongrie",
    "Poland" : "Pologne",
    "Romania" : "Roumanie",
    "Slovakia" : "Slovaquie",
    "Slovenia" : "Slovénie",
    "Sweden" : "Suède",
    "Estonia" : "Estonie",
    "Latvia" : "Lettonie",
    "Lithuania" : "Lituanie",
    "Czech Republic" : "République Tchèque",
    "Croatia" : "Croatie",
    "Greece" : "Grèce",
    "Macedonia" : "Macédoine",
    "Cameroon" : "Cameroun",
    "Lebanon" : "Liban",
    "Equatorial Guinea" : "Guinée Equatoriale",
    "Tunisia" : "Tunisie",
    "Northern Ireland" : "Irlande du Nord",
    "Fidji" : "Fidji",
    "Egypt" : "Egypte",
    "Mauritius" : "Ile Maurice",
    "Uganda" : "Ouganda",
    "Ireland" : "Irelande",
    "Vanuatu" : "Vanuatu",
    "Congo" : "Congo",
    "French Polynesia" : "Polynésie Française",
    "Morocco" : "Maroc",
    "Tanzania" : "Tanzanie",
    "Nigeria" : "Nigeria",
    "Gabon" : "Gabon",
    "Tchad" : "Tchad",
    "Kenya" : "Kenya",
    "Ghana" : "Ghana",
    "Malawi" : "Malawi",
    "Niger" : "Niger",
    "Togo" : "Togo",
    "Côte d'Ivoire" : "Côte d'Ivoire",
    "Mali" : "Mali",
    "Sierra Leone" : "Sierra Leone",
    "Mozambique" : "Mozambique",
    "Centrafrique" : "Centrafrique",
    "Senegal" : "Senegal",
    "Burkina Faso" : "Burkina Faso",
    "République Démocratique du Congo" : "République Démocratique du Congo",
    "Guinée Conakry" : "Guinée Conakry",
    "Zimbabwe" : "Zimbabwe",
    "Australia" : "Australie",
    "Honduras" : "Honduras",
    "Nouvelle-Calédonie" : "Nouvelle-Calédonie",
    "Kenya" : "Kenya",
    "Papouasie Nouvelle Guinée" : "Papouasie Nouvelle Guinée",
    "Zambia" : "Zambia",
    "Madagascar" : "Madagascar",
    "Liberia" : "LiberiaGuadeloupe",
    "GuadeloupeKingdom of Saudi Arabia" : "Royaume d'Arabie Saoudite",
    "Serbia" : "Serbie",
    "Montenegro" : "Montenegro",
    "Kosovo" : "Kosovo",
    "Bosnia and Herzegovina" : "Bosnie-Herzégovine",
    "Barbados" : "Barbades",
    "Angola" : "Angola",

    // TicketHelperModal
    "Help": "Aide",
    "Till receipt": "Ticket de caisse",
    "Forecourt receipt": "Ticket piste",
    "Close": "Fermer",

    // Home
    "Dear Customer,": "Cher Client,",
    "TotalEnergies Belgium now offers you, through this site, a unique tool for editing and receiving in a personalized way your invoices which are linked to your purchases made at our service stations.": "TotalEnergies Belgium vous propose à présent, au travers de ce site, un outil unique pour éditer et recevoir de façon personnalisée vos factures qui sont liées à vos achats effectués dans nos stations-services.",
    "To edit and receive an invoice corresponding to your purchases made in stations, all you need to do is bring your ticket: you will be asked to provide the number, amount, date and name of the station. These references allow us to retrieve the data that will be the subject of an invoice to you. The procedure for editing your invoice is made simple and intuitive, let yourself be guided!": "Pour éditer et recevoir une facture qui correspond à vos achats réalisés en stations, il vous suffit de vous munir de votre ticket: vous serez invités à en communiquer le numéro, le montant, la date et le nom de la station. Ces références nous permettent de récupérer les données qui feront l’objet d’une facture à votre attention. La marche à suivre pour l’édition de votre facture est rendue simple et intuitive, laissez-vous guider!",
    "If, however, the station in which you made your purchases was not in the list of stations offered, we invite you to contact the manager of this station directly, who will be happy to produce an invoice for you that corresponds to your purchases.": "Si toutefois la station dans laquelle vous avez fait vos achats ne se trouvait pas dans la liste des stations proposées, nous vous invitons à prendre contact directement avec le gérant de cette station qui se fera un plaisir de vous produire une facture qui correspond à vos achats.",
    "Finally, if you want to know the absolute ease of billing, we suggest you opt for a Total fuel card. To do this, simply click on the link below:": "Enfin, si vous souhaitez connaître la facilité absolue en terme de facturation, nous vous proposons d’opter pour une carte carburant Total. Pour cela, il vous suffit de cliquer sur le lien ci-dessous:",
    "link_opt_total_card_href": "https://services.totalenergies.be/fr/professionnels/nos-cartes-carburant",
    "link_opt_total_card_label": "Nos cartes carburant | TotalEnergies Belgique",
    "TotalEnergies Belgium and your station thank you for your trust.": "TotalEnergies Belgium et votre station vous remercient pour votre confiance.",

    // Help
    "For any question relating to the use of this site, you can send an email to": "Pour toute question relative à l'utilisation de ce site, vous pouvez envoyer un email à",

    // Footer
    "Terms of service": "Conditions générales d'utilisation",
    "Personal data & cookies": "Données personnelles & cookies",
    "Sitemap": "Plan du site",
    "General conditions of sale": "Conditions générales de vente",
    "All our sites": "Tous nos sites",
    "Follow us": "Suivez-nous",
    "Follow us on Facebook (new window)": "Suivez-nous sur Facebook (nouvelle fenêtre)",
    "Follow us on Twitter (new window)": "Suivez-nous sur Twitter (nouvelle fenêtre)",
    "Follow us on LinkedIn (new window)": "Suivez-nous sur LinkedIn (nouvelle fenêtre)",
    "Follow us on YouTube (new window)": "Suivez-nous sur YouTube (nouvelle fenêtre)",
    "Follow us on Instagram (new window)": "Suivez-nous sur Instagram (nouvelle fenêtre)",
}
